import * as bootstrap from 'bootstrap'
import { Livewire, Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';

Livewire.on('open-modal', function({ id }) {
    Alpine.nextTick(() => {
        window.openModal(id);
    })
})

Livewire.on('close-modal', ({ id }) => {
    Alpine.nextTick(() => {
        window.closeModal(id);
    })
})

window.openModal = function(id) {
    let target_el = document.getElementById(id);
    if (target_el) {
        let modal = bootstrap.Modal.getOrCreateInstance(target_el);
        if (modal) {
            modal.show();
        }
    }
}

window.closeModal = function(id) {
    let target_el = document.getElementById(id);
    if (target_el) {
        let modal = bootstrap.Modal.getOrCreateInstance(target_el);
        if (modal) {
            modal.hide();
        }
    }
}

